import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const inputVariants = cva(
	'flex h-10 w-full border border-input px-3 py-2 file:border-0 file:bg-transparent file:font-medium placeholder:text-text-gray/50 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid="true"]:border-input-invalid',
	{
		variants: {
			variant: {
				default:
					'rounded-full ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50 focus-visible:ring-offset-2',
				ghost: 'border-none focus:ring-0 focus:border-b focus:outline-none',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, variant, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(inputVariants({ variant, className }))}
				ref={ref}
				{...props}
			/>
		)
	},
)
Input.displayName = 'Input'

export { Input }
